<template>
  <div>
    <v-row class="pt-5 mt-3">
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/wettkampf/${$route.params.veranstaltung}`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-clipboard-list</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-light">
          ANWESENHEITSLISTE
        </h3>
        <h2 class="white--text font-weight-bold">
          {{ veranstaltung.data.name }}
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="veranstaltung.data">
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.beginn.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="2"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        BIS
      </v-col>
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.ende.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="10"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          Am
          <span class="font-weight-bold">
            {{ parseDate(veranstaltung.data.beginn.datum) }}
          </span>
          von
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
          bis
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }} Uhr
          </span>
        </h4>
      </v-col>
      <v-col cols="10" class="text-center" v-if="user.data.rolle == 'Vorstand'">
        <v-btn
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          @click="generatePDF()"
          rounded
          :loading="generating"
          :disabled="
            (zuschauer.length == 0 && tabs == 0) ||
            (sportler.length == 0 && tabs == 1)
          "
        >
          <v-icon class="mr-2">mdi-download</v-icon>
          PDF DOWNLOAD
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-tabs
        fixed-tabs
        :background-color="template.colors.primary"
        :color="template.colors.inline_primary_text"
        light
        v-model="tabs"
      >
        <v-tab>
          <v-badge color="black" :content="zuschauer.length">
            Zuschauer
          </v-badge>
        </v-tab>
        <v-tab>
          <v-badge color="black" :content="sportler.length">
            Sportler
          </v-badge>
        </v-tab>
        <v-tab-item style="height: 60vh; overflow-y: scroll;">
          <v-row
            justify="center"
            v-if="zuschauer.length == 0"
            style="min-height: 30vh;"
          >
            <v-col align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine Zuschauer erfasst.
              </h3>
            </v-col>
          </v-row>
          <v-list v-if="zuschauer.length > 0">
            <v-list-item v-for="(m, index) in zuschauer" :key="index" link>
              <v-list-item-avatar>
                <v-icon>mdi-check-bold</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ m.person.vorname }} {{ m.person.name }}
                  <small class="font-weight-light">({{ m.aktivitaet }})</small>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    parseDate(m.zutritt.datum) +
                    ' von ' +
                    m.zutritt.uhrzeit +
                    ' bis ' +
                    m.verlassen.uhrzeit
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
        <v-tab-item style="height: 60vh; overflow-y: scroll;">
          <v-row
            style="min-height: 30vh;"
            justify="center"
            v-if="sportler.length == 0"
          >
            <v-col align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine Sportler erfasst.
              </h3>
            </v-col>
          </v-row>
          <v-list three-line v-if="sportler.length > 0">
            <v-list-item v-for="(m, index) in sportler" :key="index" link>
              <v-list-item-avatar>
                <v-icon>mdi-check-bold</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ m.person.vorname }} {{ m.person.name }}
                  <small class="font-weight-light">({{ m.aktivitaet }})</small>
                </v-list-item-title>
                <v-list-item-subtitle v-if="m.disziplin">
                  {{ m.disziplin.name }}
                  {{
                    m.disziplin.altersklasse
                      ? '(' + m.disziplin.altersklasse + ')'
                      : ''
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{
                    parseDate(m.zutritt.datum) +
                    ' - ' +
                    m.zutritt.uhrzeit +
                    ' bis ' +
                    (m.verlassen.datum != m.zutritt.datum
                      ? parseDate(m.verlassen.datum) + ' - '
                      : '') +
                    m.verlassen.uhrzeit +
                    ' Uhr'
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import jsPDF from 'jspdf/dist/jspdf.es'
import autoTable from 'jspdf-autotable'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      zuschauer: [],
      sportler: [],
      generating: false,
      tabs: 1,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
      this.filter()
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    filter() {
      var i
      var liste = this.veranstaltung.anwesenheit
      for (i = 0; i < liste.length; i++) {
        if (
          liste[i].aktivitaet == 'Sportler' ||
          liste[i].aktivitaet == 'Trainer'
        ) {
          this.sportler.push(liste[i])
        } else {
          this.zuschauer.push(liste[i])
        }
      }
    },
    generatePDF() {
      const doc = new jsPDF({ orientation: 'l', format: 'a4' })
      var index = 1
      var rows = []
      var headers = []
      var liste = []
      var typ = ''
      if (this.tabs == 1) {
        liste = this.sportler
      } else {
        liste = this.zuschauer
      }
      var veranstaltungname = this.veranstaltung.data.name
      doc.text(
        this.user.data.verein.name +
          ' - ' +
          veranstaltungname +
          '\n' +
          'Anwesenheitsliste' +
          ' ' +
          this.parseDate(this.veranstaltung.data.beginn.datum) +
          ' bis ' +
          this.parseDate(this.veranstaltung.data.ende.datum),
        10,
        10,
      )
      rows = []
      if (this.template.var.verband == 'DEU') {
        headers = [
          [
            'Name',
            'Vorname',
            'Adresse',
            'Telefon',
            'Aktivität',
            'Gruppe',
            'Zeitpunkt',
          ],
        ]
      } else {
        headers = [
          [
            'Name',
            'Vorname',
            'Adresse',
            'Telefon',
            'Aktivität',
            'Disziplin',
            'Anwesenheit',
            '3G-Info',
          ],
        ]
      }
      liste.forEach((p) => {
        rows.push([
          p.person.name,
          p.person.vorname,
          p.person.adresse + ', ' + p.person.plz + ' ' + p.person.ort,
          p.person.telefon,
          p.aktivitaet,
          p.disziplin
            ? p.disziplin.name +
              '' +
              (p.disziplin.altersklasse
                ? '(' + p.disziplin.altersklasse + ')'
                : '')
            : '',
          this.parseDate(p.zutritt.datum) +
            ' - ' +
            p.zutritt.uhrzeit +
            ' bis ' +
            (p.verlassen.datum != p.zutritt.datum
              ? this.parseDate(p.verlassen.datum) + ' - '
              : '') +
            p.verlassen.uhrzeit,
          p.ggg ? this.translate_ggg(p.ggg) : '',
        ])
      })

      autoTable(doc, {
        head: headers,
        body: rows,
        theme: 'striped',
        headStyles: {
          fontStyle: 'bold',
          fillColor: this.template.colors.primary,
          textColor: this.template.colors.inline_primary_text,
        },
        startY: 30,
      })
      doc.save('Anwesenheitsliste_' + veranstaltungname + '.pdf')
    },
    translate_ggg(ggg) {
      var str = ''
      if (ggg.geburtsdatum) {
        str += 'Geb.: ' + ggg.geburtsdatum
      }
      if (ggg.getestet === true) {
        if (ggg.getestet === true) {
          str += ' | '
        }
        str += 'Negativ getestet'
      }
      if (ggg.genesen === true) {
        if (ggg.getestet === true) {
          str += ' & '
        }
        str += 'Genesen'
      }
      if (ggg.geimpft === true) {
        if (ggg.getestet === true || ggg.genesen === true) {
          str += ' & '
        }
        str += 'Geimpft'
      }
      return str
    },
  },
}
</script>
>
